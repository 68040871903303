// @import "https://fonts.googleapis.com/css?family=Roboto:300,400,500,700";

// Colors
$black: #121212;
$white: #ffffff;
$orange: #ff4700;
$blue: #1d2d3a;

// Mixins
@mixin generate-gradient($color) {
  background: -moz-linear-gradient(
    top,
    rgba($color, 0) 0%,
    rgba($color, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba($color, 0)),
    color-stop(100%, rgba($color, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba($color, 0) 0%,
    rgba($color, 1) 100%
  );
  background: -o-linear-gradient(top, rgba($color, 0) 0%, rgba($color, 1) 100%);
  background: -ms-linear-gradient(
    top,
    rgba($color, 0) 0%,
    rgba($color, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba($color, 0) 0%,
    rgba($color, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff4800', endColorstr='#ff4800', GradientType=0 );
}

// Styles
body {
  align-items: center;
  // background: darken($blue, 10);
  // display: flex;
  font-family: "Roboto", sans-serif;
  color: white;
  // height: 100vh;
  // justify-content: center;
}

audio {
  display: none;
}

.Player {
  background: darken($blue, 3);
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 5px 10px -5px rgba($black, 1);
  height: 667px;
  position: relative;
  width: 375px;
  margin: auto;
  margin-top: 20px;

  .Background {
    width: 150%;
    height: 150%;
    position: absolute;
    top: -25%;
    left: -25%;
    background: url(https://funkadelphia.files.wordpress.com/2012/09/odesza-summers-gone-lp.jpg);
    background-size: cover;
    background-position: center center;
    opacity: 0.2;
    filter: blur(10px);
  }

  .Title {
    width: 300px;
    margin: 50px auto;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    position: relative;
  }

  .Artwork {
    // max-width: 300px;
    height: 250px;
    // background: url(https://funkadelphia.files.wordpress.com/2012/09/odesza-summers-gone-lp.jpg);
    background-size: cover;
    background-position: center center;
    border-radius: 4px;
    margin: auto;
    box-shadow: 0 5px 10px -5px rgba($black, 0.25);
    position: relative;
  }

  .TrackInformation {
    width: 300px;
    margin: 30px auto;
    text-align: center;
    position: relative;

    .Name {
      font-size: 24px;
      margin-bottom: 10px;
      font-weight: 300;
    }

    .Artist {
      font-size: 16px;
      margin-bottom: 5px;
      font-weight: 500;
    }

    .Album {
      font-size: 12px;
      opacity: 0.5;
    }
  }

  .Scrubber {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20%;
    opacity: 0.2;
    transition: opacity 0.25s ease;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    &:active {
      opacity: 1;
    }

    .Scrubber-Progress {
      @include generate-gradient($orange);
      height: 100%;
      width: 0%;
      transition: width 0.25s ease;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  .Timestamps {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    pointer-events: none;
    z-index: 2;
    width: 100%;

    .Time {
      font-size: 12px;
    }
  }

  .Controls {
    position: absolute;
    bottom: 8%;
    pointer-events: none;
    margin: auto;
    left: 0;
    right: 0;

    .Button {
      height: 75px;
      width: 75px;
      border: 2px solid rgba($white, 0.5);
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 75px;
      box-shadow: 0 5px 10px 0px rgba($black, 0.125);
      margin: auto;
      pointer-events: all;
      background: $white;

      &:active {
        transform: scale(0.98);
        background: $white;

        .fa {
          color: $orange;
          opacity: 1;
        }
      }

      .fa {
        color: $white;
        opacity: 0.5;
        font-size: 24px;

        &.fa-play {
          margin-left: 5px;
        }
      }

      .hammertime {
        height: 50px;
        vertical-align: middle;
      }
    }
  }
}
